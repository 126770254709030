import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import Preloader from "./components/Preloader/Preloader";
import Timer from "./components/Countdown/Timer";
import Optin from "./components/Optin/Optin";

import "./styles.css";
import Wallet from './Wallet';

function App() {
  return (
    <div className="App">
      <Wallet />
        <div className="container">
          <h1>
            Check out the Xenon Ecosystem Documents to learn more!
          </h1>
          <div className="btncont">
            <a href="https://docs.xenoneco.app" 
              className="btn effect01" 
              target="_blank">
              <span>See the Docs!
              </span>
            </a>
          </div>
          <h2>
            XENON COIN Initial Funding Round
            <br />
            Coming Soon
          </h2>
          <Timer />
          <Optin />
          <Preloader />
        </div>
      </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <App />, 
  rootElement
);
