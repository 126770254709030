import React, { Component } from "react";
import "./Optin.css";

class Optin extends Component {

  render() {
    return (
      <div className="optin">
        <p>Want to be the first to know when we launch?</p>
        <button 
            type="button"
            value="Submit contact information"
            onClick={(e) => {
                e.preventDefault();
                window.location.href='https://docs.google.com/forms/d/e/1FAIpQLScMHSF_YsGEVoVSfh8mLrDndLbq414Kmbu5TTWEwydOvlZ0DA/viewform?usp=sf_link';
            }}
        >Submit Contact Information</button>
      </div>
    );
  }
}

export default Optin;
